import {KitButton, KitCard} from '@boystownorg/bi-cms-component-lib';
import {useTheme} from '@emotion/react';
import {Box, Tooltip, Typography} from '@mui/material';
import hex2rgba from '../../services/colorUtils/hex2rgba';
import {formatEventDetails} from '../../services/dateUtils';
import DOMPurify from 'dompurify';
import React, {useMemo} from 'react';
import DashedDivider from '../common/DashedDivider';
import {getCurrencySymbol} from '../../services/currencyUtils';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DisplayBody from '../virtual-workshop/DisplayBody';

const VirtualWorkshopCard = ({item, isSmallScreen}) => {
    const {
        startDateTime, endDateTime, timeZone, name, amountTaxInclusive, currencyCode,
        registerUri, registerMessage, productInfo, nonSupportTierTags, supportTierTags
    } = item;

    const theme = useTheme();
    const {date, time} = formatEventDetails(startDateTime, endDateTime, timeZone);

    const sanitizedDescription = useMemo(() => DOMPurify.sanitize(productInfo?.description), [productInfo?.description]);

    const register = () => {
        const sanitizedUrl = DOMPurify.sanitize(registerUri);
        window.open(sanitizedUrl, '_blank');
    };

    const maxLineLength = 70;

    const getTagList = (tags, isSmallScreen) => {
        if (!tags || tags.length < 1) return null;

        if (isSmallScreen) {
            return tags.map((tag, index) => (
                <span style={{marginRight: '10px'}} key={index}>
                    {tag}
                </span>
            ));
        }

        const textList = tags.map((tag) => tag);
        let maxTags = textList.length;

        for (let i = 1; i <= textList.length; i++) {
            const temp = textList.slice(0, i).join(', ');
            if (temp.length > maxLineLength) {
                maxTags = i - 1;
                break;
            }
        }

        const truncatedTagList = textList.slice(0, maxTags).map((tag, index) => (
            <span style={{marginRight: '10px'}} key={index}>
                {tag}
            </span>
        ));

        if (tags.length > maxTags) {
            const tooltipTags = textList.slice(maxTags).join(', ');
            truncatedTagList.push(
                <Tooltip title={tooltipTags} arrow key={tooltipTags}>
                    <MoreHorizIcon/>
                </Tooltip>
            );
        }

        return truncatedTagList;
    };

    return (
        <KitCard className='lessonPlanCard' style={{width: '95%', borderRadius: '2px'}}>
            <Box
                sx={{
                    borderTopLeftRadius: '2px',
                    borderTopRightRadius: '2px',
                    backgroundColor: hex2rgba(theme.palette.tertiary.main, 0.1),
                    height: '60px',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <Tooltip title={name} arrow>
                    <Typography noWrap={!isSmallScreen} sx={{
                        fontWeight: 600,
                        fontSize: '18px',
                        lineHeight: '21px',
                        marginLeft: '75px',
                        marginRight: '30px'
                    }}>
                        {name}
                    </Typography>
                </Tooltip>
                <Box
                    sx={{
                        backgroundImage: 'url(\'/assets/rectangle-virtual-workshop.svg\')',
                        width: '78.5px',
                        height: '36px',
                        position: 'absolute',
                        top: 9,
                        left: -9,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: '#fff',
                        fontFamily: 'Source Sans 3',
                        transform: 'rotate(0deg)'
                    }}
                >
                    <Typography
                        component="span"
                        sx={{
                            fontWeight: 400,
                            fontSize: '14px',
                            lineHeight: '21px'
                        }}
                    >
                        {getCurrencySymbol(currencyCode)}
                    </Typography>
                    <Typography
                        component="span"
                        sx={{
                            fontWeight: 400,
                            fontSize: '26px',
                            lineHeight: '21px',
                        }}
                    >
                        {amountTaxInclusive}
                    </Typography>
                </Box>
            </Box>
            <Box sx={{margin: '5px 10px'}}>
                <Typography
                    sx={{
                        fontWeight: 400,
                        fontSize: '16px',
                        lineHeight: '22px',
                        margin: '10px',
                        textAlign: 'justify',
                        fontFamily: 'Source Sans 3',
                        letterSpacing: '0px',
                        color: '##000000'
                    }}
                >
                    <DisplayBody text={sanitizedDescription} isSmallScreen={isSmallScreen} clampSize={2}/>
                    <a
                        href={DOMPurify.sanitize(productInfo?.liftPage?.href)}
                        title={DOMPurify.sanitize(productInfo?.liftPage?.text)}
                        style={{
                            fontFamily: 'Source Sans 3',
                            fontWeight: 600,
                            fontSize: '14px',
                            lineHeight: '100%',
                            textTransform: 'capitalize',
                            textDecoration: 'underline',
                            textDecorationStyle: 'solid',
                            color: theme.palette.primary.light,
                        }}
                    >
                        Learn More
                    </a>
                </Typography>
            </Box>
            <DashedDivider/>
            <Box sx={{margin: '15px 20px'}}>
                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <Box component="span" sx={{textTransform: 'uppercase', fontSize: '13px', fontWeight: 400}}>
                        <Typography component="span" sx={{fontWeight: 'bold'}}>{date}</Typography>
                        <Typography component="span" display="block">{time}</Typography>
                    </Box>
                    <Box sx={{
                        textTransform: 'uppercase',
                        fontSize: '16px',
                        lineHeight: '22px',
                        fontWeight: 600,
                        color: theme.palette.primary.light
                    }}>
                        <KitButton color='info' onClick={register} round style={{
                            backgroundColor: theme.palette.primary.light,
                            marginBottom: '5px',
                            width: 108,
                            height: 32,
                            borderRadius: '41px',
                        }}>
                            {registerMessage || 'Register'}
                        </KitButton>
                    </Box>
                </Box>
            </Box>
            <DashedDivider/>
            <Box sx={{margin: '15px 20px', display: 'flex', justifyContent: 'space-between'}}>
                <Box
                    sx={{
                        fontSize: '13px',
                        lineHeight: '22px',
                        fontWeight: 400,
                        color: theme.palette.text.light,
                        textTransform: 'capitalize',
                    }}
                >
                    {getTagList(nonSupportTierTags, isSmallScreen)}
                </Box>
                <Box sx={{ fontSize: '13px', lineHeight: '22px', fontWeight: 400, color: theme.palette.text.light, textTransform: 'capitalize' }}>
                    {getTagList(supportTierTags, isSmallScreen)}
                </Box>
            </Box>
        </KitCard>
    );
};

export default VirtualWorkshopCard;