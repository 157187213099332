import React, {memo} from 'react';
import {useTheme} from '@emotion/react';

const DashedDivider = () => {
    const theme = useTheme();
    return (
        <hr style={{borderTop: `1px dashed ${theme.palette.grey['A400']}`, width: '100%'}}/>
    );
};

export default memo(DashedDivider);
