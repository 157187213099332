import React, { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import MuiAlert from '@mui/material/Alert';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import { AppBar, Button, DialogContent, Toolbar } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { useSession } from 'next-auth/react';

import { KitButton, KitCard, KitCardBody, KitCardFooter } from '@boystownorg/bi-cms-component-lib';
import { logClientException, logServerException } from 'appinsights/clientAppInsights';
import PleaseWait from 'components/common/PleaseWait';
import { hasModuleAccess } from '../../services/subscriptionUtils';
import { CircleCheckIcon, CircleMoreIcon, CircleOutlineIcon } from '../common/CustomIcons';
import LayoutContainer from '../common/LayoutContainer';
import { COMMON_ROUTES } from 'services/constants';
import { addDataLayerEvent } from 'services/dataLayerUtils';
import { getSessionStoreString } from 'services/storageUtils';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const defaultErrorMessage = 'An unexpected error has occured. Please try again.';

const NTRWMSModuleDetail = (props) => {
  const { customData } = props;

  console.log('....................NTRWMSModuleDetail customData: ', customData);

  const theme = useTheme();
  const router = useRouter();

  const [canAccessModules, setCanAccessModules] = useState(false);
  const [subModules, setSubModules] = useState();
  const [parent, setParent] = useState();
  const [subModulesSet, setSubModulesSet] = useState(false);
  const [viewCourseOpen, setViewCourseOpen] = useState(false);
  const [currentCourse, setCurrentCourse] = useState(null);
  const currentCourseRef = useRef(null);
  const { data: session, status } = useSession();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [newVersionDialogOpen, setNewVersionDialogOpen] = useState(false);
  const [selectedSubModule, setSelectedSubModule] = useState(null);

  // workaround hash issue https://github.com/vercel/next.js/issues/11109
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      setTimeout(() => {
        document.querySelector(hash)?.scrollIntoView({ behavior: 'smooth' });
      }, 250);
    }
    if (typeof document !== 'undefined') {
      const elements = document.getElementsByClassName('osano-cm-widget');
      if (elements) {
        for (let i = 0; i < elements.length; i++) {
          elements[i].classList.add('osano-cm-widget-special');
        }
      }
    }
  }, []);

  useEffect(() => {
    setSubModules(customData?.subModules);
    setParent(customData?.parentSeries);
    setSubModulesSet(true);
    //console.log('parentSeries: ', customData?.parentSeries);
    if (typeof document !== 'undefined') {
      const docVisChange = () => {
        console.log('visibilityState', document.visibilityState);
        if (document.visibilityState === 'hidden') {
          sendXapiQueueItem();
        }
      };
      document.addEventListener('visibilitychange', docVisChange);
      return () => {
        document.removeEventListener('visibilitychange', docVisChange);
      };
    }
  }, []);

  useEffect(() => {
    setCanAccessModules(hasModuleAccess(session, 'wms'));
  }, [session]);

  useEffect(() => {
    if (subModulesSet && canAccessModules) {
      fetchUserRegistrations();
    }
  }, [session?.user?.email, subModulesSet, canAccessModules]);

  const getFetchOptions = (method, body) => {
    const options = {
      method: method,
      headers: {
        'Content-Type': 'application/json',
      },
    };
    if (body) {
      options.body = JSON.stringify(body);
    }
    return options;
  };

  const fetchUserRegistrations = async (isOnClose = false) => {
    try {
      if (!session?.user?.email) {
        return;
      }
      setIsLoading(true);
      const body = {
        email: session?.user?.email,
        tags: subModules[0]?.fields?.parentModuleTags?.split(','),
      };
      const res = await fetch('/api/scorm/fetch-registrations', getFetchOptions('POST', body));
      if (res.ok && res.status === 200) {
        const bodyJson = await res.json();
        const regs = bodyJson.registrations;
        let more = bodyJson.more;

        while (more && more.length > 0) {
          const newBody = {
            email: session?.user?.email,
            more: more,
            subModsWithNewVersionFlag: body.subModsWithNewVersionFlag,
          };

          const resmore = await fetch('/api/scorm/fetch-registrations', getFetchOptions('POST', newBody));
          if (resmore.ok && resmore.status === 200) {
            const morebodyJson = await resmore.json();
            const moreregs = morebodyJson.registrations;

            moreregs.forEach((morereg, idx) => {
              regs.push(morereg);
            });

            const moreloop = morebodyJson.more;
            more = moreloop;
          }
        }

        const newItems = subModules?.map((sub) => {
          let regList = regs.filter((r) => r.course.id === sub.fields?.scormCourse?.id) || [];
          regList = regList.sort((a, b) => {
            // sort desc by instance so we can get registration - logic to match GatedWMSModuleListing
            if (!a.instance) {
              a.instance = 0;
            }
            if (!b.instance) {
              b.instance = 0;
            }
            if (a.instance > b.instance) return -1;
            if (a.instance < b.instance) return 1;
            return 0;
          });
          const reg = regList[0];
          if (reg) {
            if (isOnClose && reg.registrationCompletion === 'COMPLETED' && sub.registrationCompletion !== reg.registrationCompletion) {
              // just completed sub, send event
              addDataLayerEvent({
                'event': 'module_complete',
                'course_name': parent.fields.moduleName,
                'course_id': parent.contentID,
                'module_number': sub.fields.moduleNumber,
                'module_name': sub.fields.subModuleName,
                'module_id': sub.contentID,
              });
            }
            sub.registrationId = reg.id;
            sub.registrationCompletion = reg.registrationCompletion;
            sub.registrationSuccess = reg.registrationSuccess;
            sub.totalSecondsTracked = reg.totalSecondsTracked;
            sub.registrationCompletionAmount = reg.registrationCompletionAmount;
            sub.firstAccessDate = reg.firstAccessDate;
            sub.lastAccessDate = reg.lastAccessDate;
            sub.completionDate = reg.conmpletionDate;
            sub.score = reg.score;
            sub.activityDetails = reg.activityDetails;
            sub.newVersionInScorm = false;
            if (sub.fields.hasNewVersion === 'true') {
              const versionNum = parseInt(sub.fields.newVersionNumber, 10);
              if (!isNaN(versionNum)) {
                if (reg.course.version < versionNum) {
                  sub.newVersionInScorm = true;
                  sub.newScormVersionNumber = versionNum;
                }
              }
            }
          }
          return sub;
        });

        // Calculate Course Completion
        let totalCourses = 0;
        let totalComplete = 0;
        newItems.forEach((s, i) => {
          totalCourses += 1;
          if (s.registrationCompletion === 'COMPLETED' && (s.registrationSuccess === 'PASSED' || s.registrationSuccess === 'UNKNOWN')) {
            totalComplete += 1;
          }
        });
        if (isOnClose && totalComplete === totalCourses) {
          addDataLayerEvent({
            'event': 'course_complete',
            'course_name': parent.fields.moduleName,
            'course_id': parent.contentID,
          });
        }
        setSubModules(newItems);
        //setSeriesComplete(((totalComplete / totalCourses) * 100).toFixed(0));
      } else {
        throw new Error(`fetUserRegistrations failed ${res.status}`);
      }
      setIsLoading(false);
    } catch (error) {
      setErrorMessage(defaultErrorMessage);
      setIsLoading(false);
      console.log(error);
      logClientException(error);
    }
  };

  const handleAskForNewVersion = async (submod) => {
    setSelectedSubModule(submod);
    setNewVersionDialogOpen(true);
  };
  const handlePlaySubmodule = async (submod, useNewVersion = false) => {
    try {
      setIsLoading(true);
      const body = {
        courseId: submod.fields.scormCourse.id,
        registrationId: submod.registrationId,
        title: submod.fields.scormCourse.title,
        product: 'wms',
        tags: submod.fields.parentModuleTags?.split(','),
        useNewVersion: useNewVersion,
        newVersionNum: submod.newScormVersionNumber,
      };
      if (getSessionStoreString('utm_source')) {
        body.utm_source = getSessionStoreString('utm_source');
      }
      if (getSessionStoreString('utm_medium')) {
        body.utm_medium = getSessionStoreString('utm_medium');
      }
      if (getSessionStoreString('utm_campaign')) {
        body.utm_campaign = getSessionStoreString('utm_campaign');
      }
      if (getSessionStoreString('utm_content')) {
        body.utm_content = getSessionStoreString('utm_content');
      }
      const res = await fetch('/api/scorm/register-build-link', getFetchOptions('POST', body));
      if (res.ok && res.status === 200) {
        const bodyJson = await res.json();
        const launchLink = bodyJson.launchLink;
        const registrationId = bodyJson.registrationId;
        const newItems = subModules.map((sub) => {
          if (sub.contentID === submod.contentID) {
            sub.launchLink = launchLink;
            sub.registrationId = registrationId;
          }
          return sub;
        });
        // if starting first module in series, send course start event
        let firstMod = subModules.find((s) => s.hasOwnProperty('registrationCompletion')) === undefined;
        if (firstMod) {
          addDataLayerEvent({
            'event': 'course_start',
            'course_name': parent.fields.moduleName,
            'course_id': parent.contentID,
          });
        }
        // module start event
        addDataLayerEvent({
          'event': 'module_start',
          'course_name': parent.fields.moduleName,
          'course_id': parent.contentID,
          'module_number': submod.fields.moduleNumber,
          'module_name': submod.fields.subModuleName,
          'module_id': submod.contentID,
        });
        setSubModules(newItems);
        setCurrentCourse({ launchLink: launchLink, name: submod.fields?.subModuleName });
        currentCourseRef.current = submod;
        setViewCourseOpen(true);
      } else {
        throw new Error(`registerLearner failed ${res.status}`);
      }
      setIsLoading(false);
    } catch (error) {
      setErrorMessage(defaultErrorMessage);
      setIsLoading(false);
      console.log(error);
      logClientException(error);
    }
  };

  const handleCloseCourse = async () => {
    sendXapiQueueItem();
    await fetchUserRegistrations(true);
    setViewCourseOpen(false);
    setCurrentCourse(null);
    currentCourseRef.current = null;
  };

  const sendXapiQueueItem = () => {
    if (currentCourseRef.current) {
      const submod = currentCourseRef.current;
      if (submod) {
        let url = `/api/scorm/xapi-queue?rid=${submod.registrationId}&cid=${submod.fields.scormCourse.id}&sid=${session?.user?.school_id}&sn=${session?.user?.school_name}&did=${session?.user?.district_id}&dn=${session?.user?.district_name}`;
        navigator.sendBeacon(url);
      }
    }
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };

  const getModuleLabel = (specialLabel, mod) => {
    if (specialLabel && specialLabel.length > 0) {
      return <span id={specialLabel}>{specialLabel}</span>;
    } else {
      return <span id={'mod_' + (mod + 1)}>{'Module ' + (mod + 1)}</span>;
    }
  };

  const getModuleCard = (s, i, totalSubs) => {
    console.log('........................s: ', s);
    console.log('........................totalSubs: ', totalSubs);

    let markAsNew = false;

    if (s.fields.isNew === 'true') {
      markAsNew = true;
    }

    let courseState = !canAccessModules ? 'Register to view' : 'Watch';
    if (s.registrationCompletion === 'COMPLETED' && (s.registrationSuccess === 'PASSED' || s.registrationSuccess === 'UNKNOWN')) {
      courseState = 'Watch Again';
    } else if (s.registrationCompletion === 'COMPLETED' && s.registrationSuccess === 'FAILED') {
      courseState = 'Continue';
    } else if (s.registrationCompletion === 'INCOMPLETE') {
      courseState = 'Continue';
    }

    let secondaryAction = <></>;
    let headerIcon = <span></span>;
    if (courseState === 'Register to view') {
      const text = !session?.user?.email ? 'Sign in to view modules' : 'View Plans';
      secondaryAction = (
        <KitButton
          aria-label={courseState}
          color='success'
          round
          size='sm'
          style={{ width: text === 'View Plans' ? '140px' : '200px' }}
          onClick={() => {
            // if logged in
            if (text === 'View Plans') {
              router.push(COMMON_ROUTES.PricingPage);
            } else {
              // redirect to sign in with redirect flag
              router.push(`${COMMON_ROUTES.SignIn}?redirect=${encodeURIComponent(router.asPath)}`);
            }
          }}
          title={text}
        >
          {text}
        </KitButton>
      );
    } else if (s?.fields?.scormCourse?.id) {
      if (s.newVersionInScorm) {
        secondaryAction = (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px' }}>
            <KitButton
              aria-label={courseState}
              round
              size='sm'
              onClick={() => handleAskForNewVersion(s)}
              title='Play'
              style={{ backgroundColor: theme.palette.info.light }}
            >
              <PlayCircleIcon /> Watch
            </KitButton>
            <Typography
              component='span'
              sx={{
                color: theme.palette.grey[800],
                fontSize: '12px',
                fontWeight: 400,
              }}
            >
              Watch the updated version
            </Typography>
          </Box>
        );
      } else {
        secondaryAction = (
          <KitButton
            aria-label={courseState}
            round
            size='sm'
            onClick={() => handlePlaySubmodule(s)}
            title='Play'
            style={{ backgroundColor: theme.palette.brandedAqua.main }}
          >
            <PlayCircleIcon /> {courseState}
          </KitButton>
        );
      }
      if (courseState === 'Watch Again') {
        headerIcon = <CircleCheckIcon sx={{ color: '#B7BF10', height: '1.25em', width: '1.25em' }} />;
      } else if (courseState === 'Continue') {
        headerIcon = <CircleMoreIcon sx={{ color: '#F2A900', height: '1.25em', width: '1.25em' }} />;
      } else if (courseState === 'Watch') {
        headerIcon = <CircleOutlineIcon sx={{ color: theme.palette.tertiary.main, height: '1.15em', width: '1.15em' }} />;
      }
    } else {
      secondaryAction = <ComingSoon>COMING SOON</ComingSoon>;
    }

    return (
      <KitCard
        key={s.contentID}
        style={{
          width: '90%',
          minHeight: '170px',
          border: 'none',
          borderRadius: 0,
          boxShadow: 'none',
          marginTop: 0,
          marginBottom: 0,
        }}
      >
        <React.Fragment key={s.contentID}>
          <KitCardBody style={{ padding: '1.00rem, 1.00rem' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography sx={{ fontSize: '18px', fontWeight: 'bold', lineHeight: '24px', marginBottom: '5px' }}>
                {getModuleLabel(s.fields.specializedModuleLabel, i)}
              </Typography>
            </Box>
            {s.newVersionInScorm && (
              <>
                <Box
                  id='version-flag-box'
                  sx={{
                    '& span#version-flag-span::after': {
                      content: '""',
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      widht: 0,
                      height: 0,
                      borderLeft: '16px solid transparent',
                      borderTop: '18px solid #fff',
                      clipPath: 'polygon(100% 0, 0 100%, 100% 100%)',
                      marginRight: '-1px',
                    },
                  }}
                >
                  <span
                    id='version-flag-span'
                    style={{
                      position: 'relative',
                      backgroundColor: theme.palette.info.light,
                      color: theme.palette.common.white,
                      borderRadius: '5px',
                      width: '80px',
                      borderLeft: '2px solid #fff',
                      borderTop: '2px solid #fff',
                      borderBottom: '2px solid #fff',
                      paddingLeft: '6px',
                      paddingRight: '10px',
                    }}
                    className='updated-flag'
                  >
                    UPDATED
                  </span>
                  <Typography
                    component='span'
                    sx={{
                      display: s.fields?.newVersionDescription ? '' : 'none',
                      marginLeft: '10px',
                      color: theme.palette.grey[800],
                      fontSize: '1.00rem',
                      lineHeight: '1.25rem',
                      fontWeight: 400,
                      overflow: 'hidden',
                    }}
                  >
                    {s.fields?.newVersionDescription}
                  </Typography>
                </Box>
                <br />
              </>
            )}
            {markAsNew && (
              <>
                <Box
                  id='new-flag-box'
                  sx={{
                    '& span#new-flag-span::after': {
                      content: '""',
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      widht: 0,
                      height: 0,
                      borderLeft: '16px solid transparent',
                      borderTop: '18px solid #fff',
                      clipPath: 'polygon(100% 0, 0 100%, 100% 100%)',
                      marginRight: '-1px',
                    },
                  }}
                >
                  <span
                    id='new-flag-span'
                    style={{
                      position: 'relative',
                      backgroundColor: theme.palette.info.light,
                      color: theme.palette.common.white,
                      borderRadius: '5px',
                      width: '80px',
                      borderLeft: '2px solid #fff',
                      borderTop: '2px solid #fff',
                      borderBottom: '2px solid #fff',
                      paddingLeft: '6px',
                      paddingRight: '10px',
                    }}
                    className='new-flag'
                  >
                    NEW
                  </span>
                </Box>
                <br />
              </>
            )}
            <Box
              id='module-content'
              sx={{
                display: 'flex',
                flexDirection: { md: 'row', xs: 'column' },
                rowGap: '20px',
                columnGap: '3rem',
                alignItems: { md: '', xs: 'center' },
              }}
            >
              <Box>
                <ModuleName>{s.fields?.subModuleName}</ModuleName>
                <Typography
                  component='p'
                  sx={{
                    color: theme.palette.grey[800],
                    fontSize: '1.00rem',
                    lineHeight: '1.25rem',
                    fontWeight: 400,
                    display: '-webkit-box',
                    ['-webkit-line-clamp']: '5',
                    ['-webkit-box-orient']: 'vertical',
                    overflow: 'hidden',
                    mb: 2,
                  }}
                >
                  {s.fields?.whatYouWillLearn}
                </Typography>
              </Box>
              <Box>
                <IconContainer>{headerIcon}</IconContainer>
              </Box>
              <Box>
                <ModuleActionContainer>{secondaryAction}</ModuleActionContainer>
              </Box>
            </Box>
            {/* This hack prevents the divider from being added after the last submod. */}
            {i + 1 < totalSubs && <DashedHr />}
          </KitCardBody>
          <KitCardFooter></KitCardFooter>
        </React.Fragment>
      </KitCard>
    );
  };

  return (
    <React.Fragment>
      <PleaseWait isLoading={isLoading} />
      <Snackbar open={errorMessage} autoHideDuration={6000} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity='error' sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>

      {parent && parent.fields && parent.fields.breadcrumbList && (
        <BreadcrumbFrame>
          <Box
            sx={{
              width: '100%',
              ml: 'auto',
              mr: 'auto',
              maxWidth: { sm: '540px', md: '768px', lg: '1180px', xl: '1180px' },
            }}
          >
            <BreadcrumbContainer>
              {parent.fields.breadcrumbList &&
                parent.fields.breadcrumbList.length > 0 &&
                parent.fields.breadcrumbList
                  .sort((a, b) => {
                    if (parseInt(a.fields.order, 10) > parseInt(b.fields.order, 10)) return 1;
                    if (parseInt(a.fields.order, 10) < parseInt(b.fields.order, 10)) return -1;
                    return 0;
                  })
                  .map((breadcrumb, index) => {
                    return (
                      <Breadcrumb key={breadcrumb.contentID}>
                        {breadcrumb.fields.breadcrumbUrl.href && breadcrumb.fields.breadcrumbUrl.href.length > 0 ? (
                          <Link href={breadcrumb.fields.breadcrumbUrl.href} passHref legacyBehavior>
                            <a target={breadcrumb.fields.breadcrumbUrl.target} rel='noopener noreferrer'>
                              {breadcrumb.fields.breadcrumbUrl.text}
                            </a>
                          </Link>
                        ) : (
                          <span>{breadcrumb.fields.breadcrumbUrl.text}</span>
                        )}
                        {breadcrumb.fields.breadcrumbUrl.href && breadcrumb.fields.breadcrumbUrl.href.length > 0 && <span>{' / '}&nbsp;</span>}
                      </Breadcrumb>
                    );
                  })}
            </BreadcrumbContainer>
          </Box>
        </BreadcrumbFrame>
      )}

      <Box
        sx={{
          width: '100%',
          ml: 'auto',
          mr: 'auto',
          maxWidth: { sm: '540px', md: '768px', lg: '1180px', xl: '1180px' },
        }}
      >
        {parent && parent.fields && parent.fields.introduction && <Introduction>{parent.fields.introduction}</Introduction>}
      </Box>

      <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CardGrid>
          <LayoutContainer
            size='md'
            sx={{
              flexWrap: 'wrap',
              justifyContent: 'center',
              gap: { md: '0 0.50rem', sm: '0 1.00rem' },
            }}
          >
            {subModules &&
              subModules.map((s, i) => {
                return getModuleCard(s, i, subModules.length);
              })}
          </LayoutContainer>
          {parent && parent.fields && parent.fields.parentPage && (
            <ReturnButton>
              <a href={parent.fields.parentPage.href} rel='noopener noreferrer'>
                <KitButton aria-label='Return to Series' color='primary' simple>
                  Return to Series
                </KitButton>
              </a>
            </ReturnButton>
          )}
        </CardGrid>
      </Box>

      <Dialog fullScreen open={viewCourseOpen} onClose={handleCloseCourse}>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton edge='start' color='inherit' onClick={handleCloseCourse} aria-label='close'>
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
              {currentCourse?.name}
            </Typography>
          </Toolbar>
        </AppBar>
        {currentCourse?.launchLink && <iframe src={currentCourse.launchLink} height='100%' width='100%' />}
      </Dialog>

      <Dialog maxWidth='sm' scroll='body' fullWidth={true} open={newVersionDialogOpen}>
        <DialogContent sx={{ marginTop: '1rem' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', mt: '10px', rowGap: '.75rem', alignItems: 'center' }}>
            <AccountTreeOutlinedIcon sx={{ color: theme.palette.info.light, fontSize: '3rem' }} />
            <Typography sx={{ fontSize: '1.5rem', fontWeight: 400, color: theme.palette.grey[800] }}>
              When you choose to watch the new version, the progress for the module will{' '}
              <span style={{ color: '#D80000', fontWeight: 'bold' }}>RESET</span>. Would you like to proceed?
            </Typography>

            <Box
              sx={{
                display: 'flex',
                flexDirection: { md: 'row', xs: 'column' },
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: '1rem',
                marginTop: '1rem',
                gap: '1rem',
              }}
            >
              <Button
                variant='outlined'
                className='need-interaction'
                size='sm'
                sx={{
                  '&.MuiButton-outlined': {
                    borderColor: theme.palette.violet.dark,
                    color: theme.palette.violet.dark,
                    '&:hover,&:focus': {
                      boxShadow:
                        '0 14px 26px -12px rgba(158, 158, 158, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(158, 158, 158, 0.42)',
                    },
                  },
                  borderRadius: '999px',
                }}
                onClick={() => {
                  setNewVersionDialogOpen(false);
                  handlePlaySubmodule(selectedSubModule);
                }}
              >
                Stay On Current Version
              </Button>
              <Button
                variant='outlined'
                className='need-interaction'
                size='sm'
                sx={{
                  backgroundColor: theme.palette.info.light,
                  borderColor: theme.palette.info.light,
                  color: theme.palette.common.white,
                  borderRadius: '999px',
                  marginLeft: { md: '8px', sm: '0' },
                  '&:hover,&:focus': {
                    backgroundColor: theme.palette.info.light,
                    borderColor: theme.palette.info.light,
                    boxShadow:
                      '0 14px 26px -12px rgba(158, 158, 158, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(158, 158, 158, 0.42)',
                  },
                }}
                onClick={() => {
                  setNewVersionDialogOpen(false);
                  handlePlaySubmodule(selectedSubModule, true);
                }}
              >
                Watch The New Version &gt;
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

NTRWMSModuleDetail.getCustomInitialProps = async ({ agility, languageCode, dynamicPageItem }) => {
  const api = agility;

  // fetch raw SubModule List for the Parent Module
  try {
    let submods = await api.getContentList({
      referenceName: 'ntrwmssubmodulelist',
      languageCode,
      expandAllContentLinks: true,
      filters: [
        { property: 'fields.parentModule_ValueField', operator: api.types.FilterOperators.EQUAL_TO, value: `"${dynamicPageItem.contentID}"` },
      ],
      sort: 'fields.moduleNumber',
      take: 25,
    });
    // parse the scormCourse object...
    submods = submods.items.map((s) => {
      try {
        if (s.fields.scormCourse) {
          s.fields.scormCourse = JSON.parse(s.fields.scormCourse);
        }
      } catch (error) {
        console.log(error);
      }
      return s;
    });

    // fetch the Parent Module (introduction)
    let parentSeries = await api.getContentItem({ contentID: dynamicPageItem.contentID, languageCode, expandAllContentLinks: true });

    return {
      subModules: submods,
      parentSeries,
    };
  } catch (error) {
    await logServerException(error);
    if (console) console.error(error);
  }
};

export default NTRWMSModuleDetail;

const DashedHr = styled('hr')(({ theme }) => ({
  borderTop: `1px dashed ${theme.palette.info.main}`,
  margin: '20px 0 -25px 0',
}));

const CardGrid = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    width: '69%',
  },
  [theme.breakpoints.down('md')]: {
    width: '95%',
  },
}));

const ModuleName = styled('div')(({ theme }) => ({
  color: theme.palette.common.black,
  fontSize: '1.5rem',
  lineHeight: '1.75rem',
  [theme.breakpoints.up('md')]: {
    width: '68%',
    margin: '0 0 15px 0',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    margin: '0 0 10px 0',
  },
}));

const IconContainer = styled('div')(({ theme }) => ({
  margin: '5px 0 10px 0',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const ModuleActionContainer = styled('div')(({ theme }) => ({
  alignSelf: 'flex-start',
  marginTop: '-5px',
  button: {
    height: '35px',
    width: '140px',
    fontSize: '1em',
    fontWeight: 400,
  },
}));

const Introduction = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  color: theme.palette.common.black,
  margin: '15px 0',
  width: '100%',
  [theme.breakpoints.up('md')]: {
    padding: '20px 15px 20px 15px',
    margin: '30px 0 40px 0',
    fontSize: '1.125rem',
    lineHeight: '1.625rem',
  },
  [theme.breakpoints.down('md')]: {
    padding: '15px 5px 15px 5px',
    margin: '15px 10px 20px 10px',
    fontSize: '1.125rem',
    lineHeight: '1.625rem',
  },
}));

const ComingSoon = styled('div')(({ theme }) => ({
  display: 'flex',
  color: theme.palette.primary.main,
  backgroundColor: 'transparent',
  margin: '7px 0',
  width: '100%',
  fontSize: '1.70rem',
  fontWeight: 700,
  textTransform: 'uppercase',
}));

const ReturnButton = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '5px 0 35px 0',
}));

const BreadcrumbFrame = styled('div')(({ theme }) => ({
  width: '100%',
  margin: '0 0 20px 0',
  [theme.breakpoints.up('md')]: {
    borderBottom: '1px solid #000',
  },
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const BreadcrumbContainer = styled('div')(({ theme }) => ({
  display: 'inline',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  [theme.breakpoints.up('md')]: {
    padding: '10px',
  },
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const BreadCrumbRow = styled('span')(({ theme }) => ({
  display: 'inline',
}));

const Breadcrumb = styled('span')(({ theme }) => ({
  display: 'inline',
  fontSize: '0.875rem',
  lineHeight: '1.125rem',
  textTransform: 'uppercase',
  color: theme.palette.primary.dark,
}));
