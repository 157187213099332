import DefaultModule from './DefaultModule';
import RichTextArea from './RichTextArea';
import FeaturedPost from './FeaturedPost';
import PostsListing from './PostsListing';
import PostDetails from './PostDetails';
import TextBlockWithImage from './TextBlockWithImage';
import Banner from './Banner';
import NTRWMSModuleListing from './NTRWMSModuleListing';
import NTRWMSModuleDetail from './NTRWMSModuleDetail';
import HomepageCarousel from './HomepageCarousel';
import SummaryWithBackgroundImage from './SummaryWithBackgroundImage';
import CallToAction from './CallToAction';
import AdminManageUsers from './admin/ManageUsers/ManageUsers';
import AdminEmailDomainAllowList from './admin/EmailDomainAllowList/EmailDomainAllowList';
import ManageCouponCodes from './admin/couponCodes/ManageCouponCodes';
import AdminFeatureSwitchList from './admin/FeatureSwitches/FeatureSwitchList';
import ManageSubscriptions from './admin/SubscriptionManagement/ManageSubscriptions';
import AdminManageVectorStore from './admin/ManageVectorStore/ManageVectorStore';
import Introduction from './Introduction';
import Quote from './Quote';
import HeroBackgroundAsImage from './HeroBackgroundAsImage';
import SummaryWithVideo from './SummaryWithVideo';
import TestimonialListing from './TestimonialListing';
import FeaturedTestimonial from './FeaturedTestimonial';
import PlanOptionList from './PlanOptionList';
import HeroImageHomepage from './HeroImageHomepage';
import TrainingBenefitsAndFeatures from './TrainingBenefitsAndFeatures';
import FeaturedVideo from './FeaturedVideo';
import ResourceListing from './ResourceListing';
import RecordedWebinarDetail from './RecordedWebinarDetail';
import SummaryWithBackgroundAndVideo from './SummaryWithBackgroundAndVideo';
import TrainingMethodSelection from './TrainingMethodSelection';
import ResourceGroupList from './ResourceGroupList';
import GatedWMSModuleListing from './GatedWMSModuleListing';
import FreeModule from './FreeModule';
import FreeModule2 from './FreeModule2';
import ActionButton from './ActionButton';
import TextWithIcon from './TextWithIcon';
import ResourceWithBullets from './ResourceWithBullets';
import Placeholder from './Placeholder';
import VideoWithBullets from './VideoWithBullets';
import VideoWithIconTitleDescButton from './VideoWithIconTitleDescButton';
import FeaturedPosts from './FeaturedPosts';
import ActionImageandButtonWithBackground from './ActionImageandButtonWithBackground';
import BackBar from './BackBar';
import FreeResources from './FreeResources';
import ContactUs from './ContactUs';
import LearnMore from './LearnMore';
import FeaturedTestimonials from './FeaturedTestimonials';
import TeamMembers from './TeamMembers';
import Video from './Video';
import SectionTitle from './SectionTitle';
import BackgroundWithBlackBox from './BackgroundWithBlackBox';
import StackedImagesWithText from './StackedImagesWithText';
import AskTheTrainer from './AskTheTrainer';
import AskTheTrainerForm from './AskTheTrainerForm';
import AskTheTrainerWithBullets from './AskTheTrainerWithBullets';
import ProfessionalLearningSeries from './ProfessionalLearningSeries';
import ActionImageWithButton from './ActionImageWithButton';
import BreadcrumbTrail from './BreadcrumbTrail';
import ActionVideoWithButton from './ActionVideoWithButton';
import ActionImageInCircle from './ActionImageInCircle';
import PlanPurchase from './plans/PlanPurchase';
import ProductStorage from './ProductStorage';
import ResourceList from './ResourceList';
import VideoLinks from './VideoLinks';
import VideoLists from './VideoLists';
import SupportFAQ from './SupportFAQ';
import BannerSimple from './BannerSimple';
import BannerAccount from './BannerAccount';
import AccountInfo from './AccountInfo';
import PageBannerWithTwoOptions from './PageBannerWithTwoOptions';
import CoachingPlanCards from './CoachingPlanCards';
import GraduateRichText from './GraduateRichText';
import IntroductionWithSideImage from './IntroductionWithSideImage';
import SummaryWithImage from './SummaryWithImage';
import AvailabilityTest from './admin/AvailabilityTest';
import GraduateOptionCards from './GraduateOptionCards';
import HTMLList from './HTMLList';
import GraduateRequestCreditJotForm from './GraduateRequestCreditJotForm';
import Newsletter from './Newsletter';
import SubscriptionTitleWithIcon from './SubscriptionTitleWithIcon';
import FreeAccountCallOut from './FreeAccountCallOut';
import SummaryWithImageAndWebinar from './SummaryWithImageAndWebinar';
import SearchResults from './SearchResults';
import LessonPlanDetails from './LessonPlanDetails';
import Paragraphs from './Paragraphs';
import HeadingText from './HeadingText';
import BannerWithImage from './BannerWithImage';
import TextBlock from './TextBlock';
import LessonPlanLanding from './LessonPlanLanding';
import Image from './Image';
import LessonPlanoftheWeek from './LessonPlanoftheWeek';
import EventRegistration from './EventRegistration';
import HeadingTextAndMedia from './HeadingTextAndMedia';
import HighlightedVideo from './HighlightedVideo';
import ObservationLandingPage from './observation/ObservationLandingPage';
import ObservationStartNew from './observation/ObservationStartNew';
import ObservationDataEntry from './observation/ObservationDataEntry';
import ObservationSessionEnd from './observation/ObservationSessionEnd';
import ObservationSessionSummary from './observation/ObservationSessionSummary';
import ObservationEventList from './observation/ObservationEventList';
import ObservationEventSummary from './observation/ObservationEventSummary';
import ObservationSessionList from './observation/ObservationSessionList';
import ObservationSessionSummaryReview from './observation/ObservationSessionSummaryReview';
import CalloutWithBackground from './CalloutWithBackground';
import ResourceDetails from './ResourceDetails';
import FreeResourceList from './FreeResourceList';
import RenewalCallout from './RenewalCallout';
import ContactUsBanner from './ContactUsBanner';
import Accordion from './accordion/Accordion';
import Embed from './Embed';
import CompleteProfile from './completeProfile/CompleteProfile';
import LoginRequired from './LoginRequired';
import RAGChat from './rag-chat/RAGChat';
import BehaviorChallengesLanding from './BehaviorChallenges/BehaviorChallengesLanding';
import BehaviorChallengesDetails from './BehaviorChallenges/BehaviorChallengesDetails';
import TopBannerWithHeading from './TopBannerWithHeading';
import CommonSenseParentingArloList from './CommonSenseParentingArloList';
import VirtualWorkshopLanding from './VirtualWorkshopLanding';

// All of the Agility Page Module Components that are in use in this site need to be imported into this index file.
// Place Page Modules in allModules array below, passing in a name and the component.

const allModules = [
  { name: 'DefaultModule', module: DefaultModule },
  { name: 'TextBlockWithImage', module: TextBlockWithImage },
  { name: 'FeaturedPost', module: FeaturedPost },
  { name: 'PostsListing', module: PostsListing },
  { name: 'PostDetails', module: PostDetails },
  { name: 'RichTextArea', module: RichTextArea },
  { name: 'Banner', module: Banner },
  { name: 'NTRWMSModuleListing', module: NTRWMSModuleListing },
  { name: 'NTRWMSModuleDetail', module: NTRWMSModuleDetail },
  { name: 'HomepageCarousel', module: HomepageCarousel },
  { name: 'SummaryWithBackgroundImage', module: SummaryWithBackgroundImage },
  { name: 'CallToAction', module: CallToAction },
  { name: 'Admin-ManageUsers', module: AdminManageUsers },
  { name: 'Admin-EmailDomainAllowList', module: AdminEmailDomainAllowList },
  { name: 'Admin-CouponCodeList', module: ManageCouponCodes },
  { name: 'AdminFeatureSwitchList', module: AdminFeatureSwitchList },
  { name: 'Admin-SubscriptionManagement', module: ManageSubscriptions },
  { name: 'AdminManageVectorStore', module: AdminManageVectorStore },
  { name: 'Introduction', module: Introduction },
  { name: 'Quote', module: Quote },
  { name: 'HeroBackgroundAsImage', module: HeroBackgroundAsImage },
  { name: 'SummaryWithVideo', module: SummaryWithVideo },
  { name: 'TestimonialListing', module: TestimonialListing },
  { name: 'FeaturedTestimonial', module: FeaturedTestimonial },
  { name: 'PlanOptionList', module: PlanOptionList },
  { name: 'HeroImageHomepage', module: HeroImageHomepage },
  { name: 'TrainingBenefitsAndFeatures', module: TrainingBenefitsAndFeatures },
  { name: 'FeaturedVideo', module: FeaturedVideo },
  { name: 'ResourceListing', module: ResourceListing },
  { name: 'RecordedWebinarDetail', module: RecordedWebinarDetail },
  { name: 'SummaryWithBackgroundAndVideo', module: SummaryWithBackgroundAndVideo },
  { name: 'TrainingMethodSelection', module: TrainingMethodSelection },
  { name: 'ResourceGroupList', module: ResourceGroupList },
  { name: 'GatedWMSModuleListing', module: GatedWMSModuleListing },
  { name: 'FreeModule', module: FreeModule },
  { name: 'FreeModule2', module: FreeModule2 },
  { name: 'ActionButton', module: ActionButton },
  { name: 'TextWithIcon', module: TextWithIcon },
  { name: 'ResourceWithBullets', module: ResourceWithBullets },
  { name: 'Placeholder', module: Placeholder },
  { name: 'VideoWithBullets', module: VideoWithBullets },
  { name: 'VideoWithIconTitleDescButton', module: VideoWithIconTitleDescButton },
  { name: 'FeaturedPosts', module: FeaturedPosts },
  { name: 'ActionImageandButtonWithBackground', module: ActionImageandButtonWithBackground },
  { name: 'BackBar', module: BackBar },
  { name: 'FreeResources', module: FreeResources },
  { name: 'ContactUs', module: ContactUs },
  { name: 'LearnMore', module: LearnMore },
  { name: 'FeaturedTestimonials', module: FeaturedTestimonials },
  { name: 'TeamMembers', module: TeamMembers },
  { name: 'Video', module: Video },
  { name: 'SectionTitle', module: SectionTitle },
  { name: 'BackgroundWithBlackBox', module: BackgroundWithBlackBox },
  { name: 'StackedImagesWithText', module: StackedImagesWithText },
  { name: 'AskTheTrainer', module: AskTheTrainer },
  { name: 'AskTheTrainerWithBullets', module: AskTheTrainerWithBullets },
  { name: 'ProfessionalLearningSeries', module: ProfessionalLearningSeries },
  { name: 'ActionImageWithButton', module: ActionImageWithButton },
  { name: 'BreadcrumbTrail', module: BreadcrumbTrail },
  { name: 'ActionVideoWithButton', module: ActionVideoWithButton },
  { name: 'ActionImageInCircle', module: ActionImageInCircle },
  { name: 'PlanPurchase', module: PlanPurchase },
  { name: 'ProductStorage', module: ProductStorage },
  { name: 'ResourceList', module: ResourceList },
  { name: 'VideoLinks', module: VideoLinks },
  { name: 'AskTheTrainerForm', module: AskTheTrainerForm },
  { name: 'VideoLists', module: VideoLists },
  { name: 'SupportFAQ', module: SupportFAQ },
  { name: 'BannerSimple', module: BannerSimple },
  { name: 'BannerAccount', module: BannerAccount },
  { name: 'AccountInfo', module: AccountInfo },
  { name: 'PageBannerWithTwoOptions', module: PageBannerWithTwoOptions },
  { name: 'CoachingPlanCards', module: CoachingPlanCards },
  { name: 'GraduateRichText', module: GraduateRichText },
  { name: 'IntroductionWithSideImage', module: IntroductionWithSideImage },
  { name: 'SummaryWithImage', module: SummaryWithImage },
  { name: 'AvailabilityTest', module: AvailabilityTest },
  { name: 'GraduateOptionCards', module: GraduateOptionCards },
  { name: 'HTMLList', module: HTMLList },
  { name: 'GraduateRequestCreditJotForm', module: GraduateRequestCreditJotForm },
  { name: 'Newsletter', module: Newsletter },
  { name: 'SubscriptionTitleWithIcon', module: SubscriptionTitleWithIcon },
  { name: 'FreeAccountCallOut', module: FreeAccountCallOut },
  { name: 'SummaryWithImageAndWebinar', module: SummaryWithImageAndWebinar },
  { name: 'SearchResults', module: SearchResults },
  { name: 'LessonPlanDetails', module: LessonPlanDetails },
  { name: 'Paragraphs', module: Paragraphs },
  { name: 'HeadingText', module: HeadingText },
  { name: 'BannerWithImage', module: BannerWithImage },
  { name: 'TextBlock', module: TextBlock },
  { name: 'LessonPlanLanding', module: LessonPlanLanding },
  { name: 'Image', module: Image },
  { name: 'LessonPlanoftheWeek', module: LessonPlanoftheWeek },
  { name: 'EventRegistration', module: EventRegistration },
  { name: 'HeadingTextAndMedia', module: HeadingTextAndMedia },
  { name: 'HighlightedVideo', module: HighlightedVideo },
  { name: 'ObservationLandingPage', module: ObservationLandingPage },
  { name: 'ObservationStartNew', module: ObservationStartNew },
  { name: 'ObservationDataEntry', module: ObservationDataEntry },
  { name: 'ObservationSessionEnd', module: ObservationSessionEnd },
  { name: 'ObservationSessionSummary', module: ObservationSessionSummary },
  { name: 'ObservationEventList', module: ObservationEventList },
  { name: 'ObservationEventSummary', module: ObservationEventSummary },
  { name: 'ObservationSessionSummaryReview', module: ObservationSessionSummaryReview },
  { name: 'ObservationSessionList', module: ObservationSessionList },
  { name: 'CalloutWithBackground', module: CalloutWithBackground },
  { name: 'ResourceDetails', module: ResourceDetails },
  { name: 'FreeResourceList', module: FreeResourceList },
  { name: 'RenewalCallout', module: RenewalCallout },
  { name: 'ContactUsBanner', module: ContactUsBanner },
  { name: 'Accordion', module: Accordion },
  { name: 'Embed', module: Embed },
  { name: 'CompleteProfile', module: CompleteProfile },
  { name: 'LoginRequired', module: LoginRequired },
  { name: 'RAGChat', module: RAGChat },
  { name: 'BehaviorChallengesLanding', module: BehaviorChallengesLanding },
  { name: 'BehaviorChallengesDetails', module: BehaviorChallengesDetails },
  { name: 'TopBannerWithHeading', module: TopBannerWithHeading },
  { name: 'CommonSenseParentingArloList', module: CommonSenseParentingArloList },
  { name: 'VirtualWorkshopList', module: VirtualWorkshopLanding },
];

export const getModule = (moduleName) => {
  if (!moduleName) return null;
  const obj = allModules.find((m) => m.name.toLowerCase() === moduleName.toLowerCase());
  if (!obj) {
    console.error(`Failed to find ${moduleName}`);
    return DefaultModule;
  }
  return obj.module;
};
