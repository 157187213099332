import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import DatePicker from 'react-multi-date-picker';
import { DateTime } from 'luxon';
import { Box } from '@mui/material';
import { FaCalendarAlt } from 'react-icons/fa';
import styles from './CustomDatePicker.module.css';

const CustomDatePicker = ({
  id,
  setValue,
  label,
  dateRanges,
  style,
  selectedDate,
}) => {
  const inputRef = useRef(null);
  const datePickerRef = useRef(null);
  const [actualDateRanges, setActualDateRanges] = useState([]);
  const [hoverMessage, setHoverMessage] = useState('');
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });

  const getMatchingRange = useCallback(
    (date) => {
      const luxonDate = DateTime.fromJSDate(date.toDate()).startOf('day');
      return actualDateRanges.find(
        (range) => luxonDate >= range.startDate && luxonDate <= range.endDate,
      );
    },
    [actualDateRanges],
  );

  const boxStyles = useMemo(
    () => ({
      '& .MuiOutlinedInput-root': {
        '& .MuiOutlinedInput-notchedOutline': {
          border: '2px solid var(--Colors-Gray-Scale-Gray-400, #B4B4B4)',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: '#1976D2',
        },
      },
      display: 'flex',
      justifyContent: 'center',
      margin: '0 auto',
      '@media (max-width: 600px)': {
        justifyContent: 'center',
      },
      position: 'relative',
      ...style,
    }),
    [style],
  );

  const handleChange = useCallback(
    (newValue) => {
      const normalizedDate = DateTime.fromJSDate(new Date(newValue)).startOf(
        'day',
      );
      setValue(normalizedDate);
      setHoverMessage('');
      datePickerRef.current?.closeCalendar();
    },
    [setValue],
  );

  useEffect(() => {
    const convertRanges = (ranges) =>
      ranges.map((range) => ({
        startDate: DateTime.fromISO(range.startDateTime).startOf('day'),
        endDate: DateTime.fromISO(range.endDateTime).startOf('day'),
        message: range.name || '',
      }));

    const convertedRanges = convertRanges(dateRanges);
    setActualDateRanges(convertedRanges);
    inputRef.current && (inputRef.current.value = '');
  }, [dateRanges, selectedDate]);

  const handleOpen = useCallback(() => {
    inputRef.current = document.getElementById(id);
  }, [id]);

  return (
    <Box sx={boxStyles}>
      <DatePicker
        ref={datePickerRef}
        id={id}
        range
        rangeHover
        value={actualDateRanges}
        onChange={handleChange}
        onlyHighlight
        onOpen={handleOpen}
        render={(currentValue, openCalendar) => (
          <Box
            sx={{
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <input
              id={id}
              ref={inputRef}
              readOnly
              onFocus={openCalendar}
              value={
                selectedDate
                  ? DateTime.fromISO(selectedDate).toFormat('MM/dd/yyyy')
                  : ''
              }
              placeholder={label}
              style={{
                flex: 1,
                padding: '10px',
                paddingRight: '40px',
                color: 'black',
                border: '2px solid var(--Colors-Gray-Scale-Gray-400, #B4B4B4)',
                borderRadius: '4px',
                cursor: 'pointer',
              }}
            />
            <FaCalendarAlt
              className={styles['custom-placeholder-icon']}
              style={{
                position: 'absolute',
                right: '10px',
                cursor: 'pointer',
              }}
              onClick={openCalendar}
            />
          </Box>
        )}
        mapDays={({ date }) => {
          const matchingRange = getMatchingRange(date);
          return matchingRange
            ? {
                onMouseEnter: (event) => {
                  setHoverMessage(matchingRange.message);
                  const rect = event.target.getBoundingClientRect();
                  setTooltipPosition({
                    top: rect.top - 30,
                    left: rect.left + rect.width / 2,
                  });
                },
                onMouseLeave: () => {
                  setHoverMessage('');
                },
                style: {
                  backgroundColor: 'rgb(66, 152, 181)',
                  color: 'white'
                },
              }
            : {};
        }}
      />
      {hoverMessage && (
        <Box
          sx={{
            position: 'fixed',
            top: `${tooltipPosition.top}px`,
            left: `${tooltipPosition.left}px`,
            transform: 'translate(-50%, -100%)',
            background: 'grey',
            color: 'whitesmoke',
            padding: '5px 10px',
            borderRadius: '4px',
            pointerEvents: 'none',
            zIndex: 1000,
            fontSize: '0.85em',
          }}
        >
          {hoverMessage}
        </Box>
      )}
    </Box>
  );
};

export default CustomDatePicker;
