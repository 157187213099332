import {DateTime} from 'luxon';

export const addHours = (numHours, date = new Date()) => {
  date.setTime(date.getTime() + numHours * 60 * 60 * 1000);
  return date;
};

export const weekOfYear = (date = new Date()) => {
  const oneJan = new Date(date.getFullYear(), 0, 1);
  const numberOfDays = Math.floor((date - oneJan) / (24 * 60 * 60 * 1000));
  return Math.ceil((date.getDay() + 1 + numberOfDays) / 7);
};

const options = { month: 'short', day: 'numeric', year: 'numeric' };

export const formatEventDetails = (startDate, endDate, timeZone) => {
  const startDateTime = new Date(startDate);
  const endDateTime = new Date(endDate);

  if (isNaN(startDateTime) || isNaN(endDateTime)) {
    throw new Error('Invalid date');
  }

  const startDateStr = startDateTime.toLocaleDateString('en-US', options).toUpperCase().replace(',', '');

  const startTimeStr = startDateTime.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  }).toUpperCase();
  const endTimeStr = endDateTime.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  }).toUpperCase();

  const isSameDate = startDateTime.getFullYear() === endDateTime.getFullYear() &&
      startDateTime.getMonth() === endDateTime.getMonth() &&
      startDateTime.getDate() === endDateTime.getDate();

  if (isSameDate) {
    const day = startDateTime.toLocaleDateString('en-US', { weekday: 'short' }).toUpperCase();
    return {
      date: `${startDateStr} (${day})`,
      time: `${startTimeStr} - ${endTimeStr} ${timeZone}`
    };
  }

  const startDay = startDateTime.getDate();
  const endDay = endDateTime.getDate();
  const startMonth = startDateTime.toLocaleDateString('en-US', { month: 'short' }).toUpperCase();
  const endMonth = endDateTime.toLocaleDateString('en-US', { month: 'short' }).toUpperCase();
  const startYear = startDateTime.getFullYear();
  const endYear = endDateTime.getFullYear();

  const startWeekday = startDateTime.toLocaleDateString('en-US', { weekday: 'short' }).toUpperCase();
  const endWeekday = endDateTime.toLocaleDateString('en-US', { weekday: 'short' }).toUpperCase();

  if (startYear === endYear) {
    return {
      date: `${startMonth} ${startDay} - ${endMonth} ${endDay} ${startYear} (${startWeekday}-${endWeekday})`,
      time: `${startTimeStr} - ${endTimeStr} ${timeZone}`
    };
  } else {
    return {
      date: `${startMonth} ${startDay} ${startYear} - ${endMonth} ${endDay} ${endYear} (${startWeekday}-${endWeekday})`,
      time: `${startTimeStr} - ${endTimeStr} ${timeZone}`
    };
  }
};

export const prepareDate = (encodeDate) => {
  try {
    const rawDate = decodeURIComponent(encodeDate);
    const parsedDate = DateTime.fromISO(rawDate);
    if (parsedDate.isValid) {
      return parsedDate.startOf('day');
    }
  } catch (error) {
    console.error('Error parsing selectedDate parameter:', error.message);
  }
  return null;
};
