import React, {useEffect, useRef, useState, useCallback} from 'react';
import {Tooltip, Typography} from '@mui/material';
import Box from "@mui/material/Box";

const DisplayBody = ({text, isSmallScreen = false, clampSize = 1, style={}}) => {
    const textRef = useRef(null);
    const [isOverflowing, setIsOverflowing] = useState(false);

    const checkOverflow = useCallback(() => {
        if (textRef.current) {
            setIsOverflowing(textRef.current.scrollHeight > textRef.current.clientHeight);
        }
    }, []);

    useEffect(() => {
        checkOverflow();
    }, [text, checkOverflow]);

    useEffect(() => {
        if (textRef.current) {
            if (isOverflowing) {
                textRef.current.setAttribute('title', text);
            } else {
                textRef.current.removeAttribute('title');
            }
        }
    }, [isOverflowing, text]);

    return (
        <Box sx={{maxWidth: '100%', display: 'flex', alignItems: 'start', ...style}}>
            {isSmallScreen ? (
                <Typography
                    ref={textRef}
                    sx={{
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden'
                    }}
                >
                    {text}
                </Typography>
            ) : isOverflowing ? (
                <Tooltip title={text} arrow>
                    <Typography
                        ref={textRef}
                        sx={{
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            WebkitLineClamp: clampSize,
                        }}
                    >
                        {text}
                    </Typography>
                </Tooltip>
            ) : (
                <Typography
                    ref={textRef}
                    sx={{
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        WebkitLineClamp: clampSize,
                    }}
                >
                    {text}
                </Typography>
            )}
        </Box>
    );
};

export default DisplayBody;