const ARLO_BASE_URL = process.env.ARLO_BASE_URL ?? 'https://boystown.arlo.co/api/2012-02-01/pub/resources/eventsearch/?region=LED';
const MAX_SIZE = 200;

const fetchFromApi = async (baseUrl, params) => {
    const url = new URL(baseUrl);
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));

    const res = await fetch(url.toString());
    if (res.status !== 200) {
        throw new Error(`Error fetching data: ${res.status} ${res.statusText}`);
    }
    return await res.json();
};

export const fetchVirtualWorkshops = async (topSize, offset = 0) => {
    const baseUrl = ARLO_BASE_URL;
    const defaultParams = {
        fields: 'EventID,Name,AdvertisedOffers,Sessions.StartDateTime,Sessions.Location,Sessions.EndDateTime,Sessions.Summary,Sessions.Name,Sessions.AdvertisedOffers,RegistrationInfo,Categories,Presenters.Profile,TimeZone,Notice,Description,Tags,TemplateTags',
        top: topSize,
        skip: offset,
        sort: 'Sessions.StartDateTime',
        includeTotalCount: true,
    };

    const params = { ...defaultParams };

    return await fetchFromApi(baseUrl, params);
};

const getFirstNonDiscountOffer = advertisedOffers => {
    if (!advertisedOffers) return undefined;
    return advertisedOffers.find(offer =>
        !offer.IsDiscountOffer && offer.OfferAmount.AmountTaxExclusive > 0
    );
};


const getCategoryProductInfo = (list, category) => {
    if (!list || !category) return undefined;
    const categoryID = category.CategoryID.toString();
    const foundItem = list.find(item => item[categoryID]);
    return foundItem ? foundItem[categoryID] : undefined;
};

export const filterSupportTierTagsPresent = (tagsForAGivenWorkshop, tagsForAll) => {
    return tagsForAGivenWorkshop.filter(tag => tagsForAll.includes(tag));
};

export const mapWorkshopsWithProductInfo = (items, arloEventMapList, supportTierTagsConfiguredForAll) => {
    return items.map(item => {
        const advertisedOffer = getFirstNonDiscountOffer(item?.AdvertisedOffers);
        const session = item?.Sessions[0];
        const category = item?.Categories[0];
        const productInfo = getCategoryProductInfo(arloEventMapList, category);
        const combinedTags = [...item?.Tags ?? [], ...item?.TemplateTags ?? []];
        return {
            startDateTime: session.StartDateTime,
            endDateTime: session.EndDateTime,
            name: item.Name,
            timeZone: item.TimeZone,
            amountTaxInclusive: advertisedOffer.OfferAmount.AmountTaxInclusive,
            currencyCode: advertisedOffer.OfferAmount.CurrencyCode,
            registerUri: item?.RegistrationInfo?.RegisterUri,
            registerMessage: item?.RegistrationInfo?.RegisterMessage,
            supportTierTags: filterSupportTierTagsPresent(combinedTags, supportTierTagsConfiguredForAll),
            nonSupportTierTags: combinedTags.filter(tag => !supportTierTagsConfiguredForAll.includes(tag)).sort(),
            productInfo: {...productInfo}
        };
    });
};

const filterCategoriesByArloIDs = (categories, categoryIDsInArlo) => {
    if (!categories) return null;
   return categories.filter(category => categoryIDsInArlo.includes(String(category.CategoryID)));
};

export const filterItemsByCategory = (items, transformedArloCategory) => {
    if (!items) return [];
    const categoryIDsInArlo = transformedArloCategory.map(obj => Object.keys(obj)[0]);
    return items.map(item => ({
        Categories: filterCategoriesByArloIDs(item.Categories, categoryIDsInArlo),
        Sessions: item.Sessions || null,
        TemplateTags: item.TemplateTags || null,
        Tags: item.Tags || null,
        RegistrationInfo: item.RegistrationInfo || null,
        Name: item.Name || null,
        TimeZone: item.TimeZone || null,
        AdvertisedOffers: item.AdvertisedOffers || null,
    }));
};

export const expandExpeditionItems = (listWithWithDesiredFields) => {
    const expandedList = [];
    if (!listWithWithDesiredFields || listWithWithDesiredFields.length === 0) return expandedList;
    listWithWithDesiredFields.forEach(item => {
        if (item.Categories && item.Categories.length > 0 && item.Categories[0].Name === 'Expedition Literacy') {
            item.Sessions.forEach(session => {
                const newItem = {...item, Name: item.Categories[0].Name +' - '+ session.Name, Sessions: [session]};
                expandedList.push(newItem);
            });
        } else {
            expandedList.push(item);
        }
    });

    return expandedList;
};

export const generateFilterMap = (categories) => {
    const result = {};
    if (!categories || categories.length === 0) return result;
    categories.forEach(category => {
        const key = category.fields.name.trim();
        const tagsUnsorted = category.fields.tags.split('; ').map(tag => tag.trim());
        result[key] = {
            label: category.fields.label.trim(),
            tags: key !== 'supportTiers' ? tagsUnsorted.sort() : tagsUnsorted
        };
    });
    return result;
};

export const mapArloCategories = (list) => {
    if (!list) return {};
    return list.map(item => ({
        [item.fields.arloCategoryID]: {...item.fields, liftPage: {...item.fields.liftPage}}
    }));
};

export const getVirtualWorkshops = async (arloEventMapList, supportTierTagsConfiguredForAll) => {
    const virtualWorkshops = await fetchVirtualWorkshops(MAX_SIZE);
    const transformedArloCategory = mapArloCategories(arloEventMapList?.items);
    const workshopsListWithDesiredFields = filterItemsByCategory(virtualWorkshops?.Items, transformedArloCategory);
    const expandedWorkshopsList = expandExpeditionItems(workshopsListWithDesiredFields);
    return mapWorkshopsWithProductInfo(expandedWorkshopsList, transformedArloCategory, supportTierTagsConfiguredForAll);
};