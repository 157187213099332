import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import {
    Box,
    Checkbox,
    Drawer,
    FormControlLabel,
    IconButton,
    Pagination,
    useMediaQuery
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { logServerException } from 'appinsights/clientAppInsights';
import { KitButton } from '@boystownorg/bi-cms-component-lib';
import PageContainer from 'components/common/subcomponents/PageContainer';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { FilterIcon } from 'components/common/subcomponents/SvgIcons';
import CustomDatePicker from '../common/CustomDatePicker';
import {
    generateFilterMap,
    getVirtualWorkshops
} from '../../services/virtualWorkshopUtils';
import VirtualWorkshopCard from './VirtualWorkshopCard';
import PleaseWait from '../common/PleaseWait';
import DisplayBody from '../virtual-workshop/DisplayBody';
import {prepareDate} from '../../services/dateUtils';

const PER_PAGE = 10;

const VirtualWorkshopLanding = ({ customData }) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
    const [workshopsListToDisplay, setWorkshopsListToDisplay] = useState(customData?.entireVirtualWorkshops ?? []);
    const [filteredWorkshops, setFilteredWorkshops] = useState([]);
    const [page, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState(customData?.entireVirtualWorkshops.length || 0);
    const [filterOpen, setFilterOpen] = useState(false);
    const scrollRef = useRef(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedFilters, setSelectedFilters] = useState({});
    const [workshopsDates, setWorkshopsDates] = useState([]);

    useEffect(() => {
        if (!isSmallScreen && filterOpen) {
            setFilterOpen(false);
        }
    }, [isSmallScreen, filterOpen]);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        if (params.has('selectedDate')) {
          setSelectedDate(prepareDate(params.get('selectedDate')));
        }
        const updatedValues = {};
        params.forEach((value, key) => {
            if (key !== 'selectedDate') {
                updatedValues[key] = JSON.parse(value);
            }
        });
        setSelectedFilters(updatedValues);
    }, [customData]);

    useEffect(() => {
        const fetchPage = () => {
            if (!workshopsListToDisplay) return;
            const datesAndColor = workshopsListToDisplay.map((workshop) => ({
              startDateTime: workshop.startDateTime,
              endDateTime: workshop.endDateTime,
              name: workshop.name,
            }));
            setWorkshopsDates(datesAndColor);
            const newWorkshops = workshopsListToDisplay.slice(0, PER_PAGE);
            setFilteredWorkshops(newWorkshops);
            setTotalCount(workshopsListToDisplay.length);
            setPage(1);
        };
        fetchPage();
        setLoading(false);
    }, [workshopsListToDisplay]);

    const clearFilter = () => {
        setLoading(true);
        setSelectedDate(null);
        setSelectedFilters({});
        const newUrl = window.location.pathname;
        window.history.replaceState({}, '', newUrl);
        setWorkshopsListToDisplay(customData?.entireVirtualWorkshops || []);
        setPage(1);
    };

    const updatePage = useCallback((p) => {
        setLoading(true);
        const startIndex = (p - 1) * PER_PAGE;
        const endIndex = startIndex + PER_PAGE;
        const newWorkshops = workshopsListToDisplay.slice(startIndex, endIndex);
        setFilteredWorkshops(newWorkshops);
        setPage(p);
        setLoading(false);
        scrollToStart();
    }, [workshopsListToDisplay]);

    useEffect(() => {
        const filterByDate = (workshops) => {
            return workshops.filter(workshop => {
                const workshopDate = new Date(workshop.startDateTime).setHours(0,0,0);
                return workshopDate >= new Date(selectedDate).setHours(0,0,0);
            });
        };

        const filterByTags = (workshops) => {
            const allSelectedTags = Object.values(selectedFilters).flat();
            return workshops.filter(workshop => {
                return allSelectedTags.every(tag => workshop.nonSupportTierTags.includes(tag) || workshop.supportTierTags.includes(tag));
            });
        };

        let filteredWorkshops = customData?.entireVirtualWorkshops;
        if (selectedDate) {
            filteredWorkshops = filterByDate(filteredWorkshops);
        }

        if (selectedFilters && Object.keys(selectedFilters).length > 0) {
            filteredWorkshops = filterByTags(filteredWorkshops);
        }
        setWorkshopsListToDisplay(filteredWorkshops);
    }, [selectedDate, selectedFilters, customData?.entireVirtualWorkshops]);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        if (selectedDate) {
            params.set('selectedDate', selectedDate);
        } else {
            params.delete('selectedDate');
        }
        const newUrl = params.toString() ? `${window.location.pathname}?${params.toString()}` : window.location.pathname;
        window.history.replaceState({}, '', newUrl);
    }, [selectedDate]);

    const getPageOfData = () => {
        return filteredWorkshops;
    };

    const filterChange = useCallback((filterType, e) => {
        const { checked, value } = e.target;
        setSelectedFilters((prev) => {
            const updatedValues = { ...prev };
            if (checked) {
                if (!updatedValues[filterType]) {
                    updatedValues[filterType] = [];
                }
                updatedValues[filterType].push(value);
            } else {
                updatedValues[filterType] = updatedValues[filterType].filter((v) => v !== value);
                if (updatedValues[filterType].length === 0) {
                    delete updatedValues[filterType];
                }
            }

            const params = new URLSearchParams(window.location.search);
            if (checked) {
                const currentValues = params.get(filterType) ? JSON.parse(params.get(filterType)) : [];
                currentValues.push(value);
                params.set(filterType, JSON.stringify(currentValues));
            } else {
                const currentValues = JSON.parse(params.get(filterType)).filter((v) => v !== value);
                if (currentValues.length > 0) {
                    params.set(filterType, JSON.stringify(currentValues));
                } else {
                    params.delete(filterType);
                }
            }
            const newUrl = params.toString() ? `${window.location.pathname}?${params.toString()}` : window.location.pathname;
            window.history.replaceState({}, '', newUrl);
            return updatedValues;
        });
    }, []);

    const renderFilterGroup = (tags, filterType) => {
        if (!tags || tags.length === 0) return;
        return tags.map((tag) => (
            <FormControlLabel
                sx={{
                    width: '90%',
                    fontSize: '13px',
                    fontWeight: 400,
                    lineHeight: '22px',
                    color: theme.palette.text.medium,
                    [theme.breakpoints.down('md')]: {
                      alignItems: 'flex-start',
                   },
                }}
                key={tag}
                control={
                    <Checkbox
                        size='small'
                        checked={selectedFilters[filterType]?.includes(tag) || false}
                        value={tag}
                        onChange={(e) => filterChange(filterType, e)}
                    />
                }
                label={
                    <DisplayBody text={tag} isSmallScreen={isSmallScreen} style={isSmallScreen? {paddingTop: '5px'} : {}}/>
                }
            />
        ));
    };

    const getFilterItems = () => {
        return (
            <>
                <Box sx={{ width: '90%' }}>
                    <Box sx={{ display: isSmallScreen ? 'flex' : 'none', justifyContent: 'flex-end', width: '100%' }}>
                        <IconButton onClick={() => setFilterOpen(false)} sx={{ color: theme.palette.text.dark }}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <CustomDatePicker
                        id="#calender-picker"
                        label="Select by Date"
                        setValue={setSelectedDate}
                        selectedDate={selectedDate}
                        dateRanges={workshopsDates}
                        style={{ width: '100%', paddingBottom: '15px' }}
                    />
                    <KitButton color='info' onClick={clearFilter} round style={{ marginBottom: '20px', width: '100%' }}>
                        Clear All Selection
                    </KitButton>
                    <FilterHeading>{customData?.filters?.supportTiers?.label}</FilterHeading>
                    <Box sx={{ marginBottom: '.75rem' }}>
                        {renderFilterGroup(customData?.filters?.supportTiers?.tags, 'supportTiers')}
                    </Box>
                    <FilterHeading>{customData?.filters?.yourGoals?.label}</FilterHeading>
                    <Box sx={{ marginBottom: '.75rem' }}>
                        {renderFilterGroup(customData?.filters?.yourGoals?.tags, 'yourGoals')}
                    </Box>
                    <FilterHeading>{customData?.filters?.focusAreas?.label}</FilterHeading>
                    <Box>
                        {renderFilterGroup(customData?.filters?.focusAreas?.tags, 'focusAreas')}
                    </Box>
                </Box>
            </>
        );
    };

    const scrollToStart = () => {
        if (scrollRef && scrollRef.current) {
            scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            if (typeof window !== 'undefined') {
                window.setTimeout(() => {
                    scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }, 250);
            }
        }
    };

    return (
        <PageContainer>
            {loading && <PleaseWait loading={loading} />}
            <Drawer
                open={filterOpen}
                onClose={() => setFilterOpen(false)}
                anchor='left'
                ModalProps={{
                    keepMounted: false,
                }}
            >
                <Box id='filterWrapper' sx={{ width: 250, padding: '.5rem', height: '100%' }} role='presentation'>
                    {getFilterItems()}
                </Box>
            </Drawer>
            <Box sx={{ display: isSmallScreen ? 'flex' : 'none' }}>
                <IconButton
                    onClick={() => {
                        if (typeof window !== 'undefined') {
                            window.scrollTo(0, 0);
                            setFilterOpen(true);
                            window.setTimeout(() => {
                                window.scrollTo(0, 0);
                            }, 250);
                        }
                    }}
                >
                    <FilterIcon fillColor={theme.palette.primary.main} height='24' width='24' />
                    <span style={{
                        fontSize: '18px',
                        lineHeight: '23.4px',
                        fontWeight: 400,
                        color: theme.palette.text.dark,
                        marginLeft: '.5rem'
                    }}> Filter</span>
                </IconButton>
            </Box>
            <Box
                ref={scrollRef}
                sx={{
                    display: { md: 'grid', xs: 'flex' },
                    flexDirection: { xs: 'column', md: 'row' },
                    gridTemplateColumns: { xs: 'auto', md: 'auto', lg: 'auto 40% 40%' },
                    marginTop: { xs: 0, md: '1.5rem' },
                }}
            >
                <div style={{
                    display: isSmallScreen ? 'none' : 'flex',
                    alignItems: 'flex-start',
                    flexDirection: 'column'
                }}>
                    {getFilterItems()}
                </div>

                <VirtualWorkshopColumn isSmallScreen={isSmallScreen}>
                    {getPageOfData().length === 0 && (<Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        textAlign: 'center',
                        marginTop: '10%',
                        marginX: '10%',
                        [theme.breakpoints.up('md')]: {
                            marginBottom: '0%',
                            height: '90vh',
                        },
                        [theme.breakpoints.down('md')]: {
                            marginBottom: '10%',
                        },
                    }}>We didn&apos;t find any upcoming sessions. Please contact us for additional scheduling
                        options.</Box>)}
                    {getPageOfData().map((l, i) => {
                        if (!isSmallScreen && i % 2 === 0) {
                            return <VirtualWorkshopCard item={l} key={i} isSmallScreen={isSmallScreen} />;
                        } else if (isSmallScreen) {
                            return <VirtualWorkshopCard item={l} key={i} isSmallScreen={isSmallScreen} />;
                        }
                    })}
                </VirtualWorkshopColumn>
                <VirtualWorkshopColumn number={2} isSmallScreen={isSmallScreen} id='planColumn2'>
                    {getPageOfData().map((l, i) => {
                        if (!isSmallScreen && i % 2 === 1) {
                            return <VirtualWorkshopCard item={l} key={i} isSmallScreen={isSmallScreen} />;
                        }
                    })}
                </VirtualWorkshopColumn>
            </Box>
            {getPageOfData().length !== 0 &&
                <Box sx={{display: 'flex', justifyContent: 'center', marginTop: '1.5rem', marginBottom: '1.5rem'}}>
                    <Pagination
                        color='primary'
                        size='large'
                        shape='circular'
                        count={Math.ceil(totalCount / PER_PAGE)}
                        page={page}
                        onChange={(e, value) => updatePage(value)}
                    />
                </Box>
            }
        </PageContainer>
    );
};

export default VirtualWorkshopLanding;

const VirtualWorkshopColumn = styled('div')(({ number, isSmallScreen }) => ({
    display: isSmallScreen && number === 2 ? 'none' : 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '.lessonPlanCard': {
        marginTop: 0,
        marginBottom: '1.25rem',
    },
    marginTop: isSmallScreen ? '1rem' : 0,
}));

const FilterHeading = styled('div')(() => ({
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '21px',
    font: 'Source Sans 3',
    color: '#000000'
}));

VirtualWorkshopLanding.getCustomInitialProps = async ({ agility, languageCode }) => {
    try {
        const virtualWorkshopCategories = await agility.getContentList({
            referenceName: 'VirtualWorkshopCategories',
            languageCode
        });
        const arloEventMapList = await agility.getContentList({
            referenceName: 'ArloEventMapList',
            languageCode
        });
        const filters = generateFilterMap(virtualWorkshopCategories?.items);
        const virtualWorkshops = await getVirtualWorkshops(arloEventMapList, filters?.supportTiers?.tags);
        return {
            filters,
            entireVirtualWorkshops: virtualWorkshops,
        };
    } catch (error) {
        await logServerException(error);
        if (console) console.error(error);
    }
};